export class NumberUtils {
  /**
   * Format number to 2 decimal
   *
   * @param number
   */
  static toFloat(number: number): number {
    return Math.round(number * 100) / 100;
  }

  /**
   * Calculate percentage between two numbers
   * @param number1
   * @param number2
   */
  static calculatePercentage(number1: number, number2: number): number {
    if (!number1 || !number2) {
      return 0;
    }

    const total = (1 - number1 / number2) * 100 * -1;

    return NumberUtils.toFloat(total);
  }
}
