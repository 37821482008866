export class StringUtils {
  /**
   * Generate random string
   *
   * @param length
   *
   * @return string
   */
  static random = (length = 24): string => {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

    let text = '';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  };
}
