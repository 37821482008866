import { ApiHelper } from './api.helper';

export const fetchGet = async <T>(path: string): Promise<T> => {
  const response = await fetch(ApiHelper.makeUrl(path), ApiHelper.makeOptions());
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};
