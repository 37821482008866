import React, { useEffect, useMemo, useState } from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import { IStatusStatistics } from '../../../../types/status.type';
import { ArrayUtils } from '../../../../utils/array.utils';
import { StatusStatisticsShape } from '../../shapes';
import StatusListBody from '../StatusListBody';
import StatusListFooter from '../StatusListFooter';
import StatusListHead from '../StatusListHead';

interface IProps {
  statistics: IStatusStatistics[];
}

const StatusList: React.FC<IProps> = React.memo(({ statistics }) => {
  const [statuses, setStatuses] = useState<string[]>([]);

  useEffect(() => {
    const uniqueStatuses = ArrayUtils.uniqueValuesByKey<IStatusStatistics, string>(
      statistics,
      'code',
    );

    setStatuses(uniqueStatuses);
  }, [statistics]);

  const statusNames = useMemo(
    () =>
      statuses.map((status) => {
        const item = statistics.findLast(({ code }) => code === status);

        return item?.status ?? status;
      }),
    [statistics, statuses],
  );

  return (
    <Grid container mt={3}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <StatusListHead statuses={statusNames} />
            <StatusListBody statistics={statistics} statuses={statuses} />
            <StatusListFooter statistics={statistics} statuses={statuses} />
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
});

StatusList.propTypes = {
  statistics: PropTypes.arrayOf(StatusStatisticsShape.isRequired).isRequired,
};

export default StatusList;
