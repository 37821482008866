import React from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SchoolLevelShape } from '../../shapes/school-level.shape';
import { ControlSx } from '../../types/control.type';
import { ISchoolLevel } from '../../types/school-level.type';
import FormSelectMultiple from '../FormSelectMultiple';

type IProps = ControlSx & {
  value: string[];
  schoolLevels: ISchoolLevel[];
  onChange: (value: string[]) => void;
  name?: string;
};

const FormSchoolLevelSelect: React.FC<IProps> = React.memo(
  ({ value, onChange, schoolLevels, name = 'schoolLevel', controlSx = {} }) => {
    const { t } = useTranslation();

    const isAllSchoolLevelChecked = React.useMemo(
      () => value.length === schoolLevels.length,
      [value.length, schoolLevels.length],
    );

    const handleAllSchoolLevelChoose = React.useCallback(() => {
      onChange(isAllSchoolLevelChecked ? [] : schoolLevels.map(({ code }) => code));
    }, [isAllSchoolLevelChecked, onChange, schoolLevels]);

    return (
      <FormSelectMultiple
        name={name}
        value={value}
        onChoose={onChange}
        label={t('general.school_level')}
        items={schoolLevels}
        controlSx={controlSx}
      >
        <FormControlLabel
          control={
            <Checkbox checked={isAllSchoolLevelChecked} onChange={handleAllSchoolLevelChoose} />
          }
          label={t('general.select_all')}
        />
      </FormSelectMultiple>
    );
  },
);

FormSchoolLevelSelect.defaultProps = {
  name: 'schoolLevel',
  controlSx: {},
};

FormSchoolLevelSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  schoolLevels: PropTypes.arrayOf(SchoolLevelShape.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  controlSx: PropTypes.object,
};

export default FormSchoolLevelSelect;
