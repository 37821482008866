import React, { useEffect } from 'react';

import { TableCell, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import PropTypes from 'prop-types';

import TableGroupRow from '../../../../components/TableGroupRow';
import { IProductType } from '../../../../types/product-type.type';
import { RecordNumber } from '../../../../types/record-number.type';
import { IRegistrationType, IStaff } from '../../../../types/staff.type';
import { ArrayUtils } from '../../../../utils/array.utils';
import { OptionsShape, StaffShape } from '../../shapes';
import { IOptions } from '../../types';

interface IProps {
  groupName: string;
  staff: IStaff[];
  options: IOptions;
}

const sxNumberCell: SxProps<Theme> = { fontWeight: 'bold', textAlign: 'right' };

type IStaffGroup = Record<IProductType, RecordNumber<IRegistrationType | 'total'>> & {
  total: number;
};

const StaffListGroup: React.FC<IProps> = React.memo(({ groupName, staff, options }) => {
  const [statistics, setStatistics] = React.useState<IStaffGroup>({
    [IProductType.COCO]: {
      [IRegistrationType.new]: 0,
      [IRegistrationType.old]: 0,
      total: 0,
    },
    [IProductType.DISE]: {
      [IRegistrationType.new]: 0,
      [IRegistrationType.old]: 0,
      total: 0,
    },
    [IProductType.CERTIFICATE]: {
      [IRegistrationType.new]: 0,
      [IRegistrationType.old]: 0,
      total: 0,
    },
    total: 0,
  });

  useEffect(() => {
    const coco = staff.map(({ COCO }) => COCO);

    const cocoNew = ArrayUtils.calculateTotal(coco, IRegistrationType.new);
    const cocoOld = ArrayUtils.calculateTotal(coco, IRegistrationType.old);

    const dise = staff.map(({ DISE }) => DISE);

    const diseNew = ArrayUtils.calculateTotal(dise, IRegistrationType.new);
    const diseOld = ArrayUtils.calculateTotal(dise, IRegistrationType.old);

    const certificate = staff.map(({ CERTIFICATE }) => CERTIFICATE);

    const certificateNew = ArrayUtils.calculateTotal(certificate, IRegistrationType.new);
    const certificateOld = ArrayUtils.calculateTotal(certificate, IRegistrationType.old);

    setStatistics({
      [IProductType.COCO]: {
        [IRegistrationType.new]: cocoNew,
        [IRegistrationType.old]: cocoOld,
        total: cocoNew + cocoOld,
      },
      [IProductType.DISE]: {
        [IRegistrationType.new]: diseNew,
        [IRegistrationType.old]: diseOld,
        total: diseNew + diseOld,
      },
      [IProductType.CERTIFICATE]: {
        [IRegistrationType.new]: certificateNew,
        [IRegistrationType.old]: certificateOld,
        total: certificateNew + certificateOld,
      },
      total: cocoNew + cocoOld + diseNew + diseOld + certificateNew + certificateOld,
    });
  }, [staff]);

  return (
    <TableGroupRow groupTitle={groupName} groupRowColSpan={options.displayPartnership ? 4 : 3}>
      <TableCell sx={sxNumberCell}>{statistics.COCO.new}</TableCell>
      <TableCell sx={sxNumberCell}>{statistics.COCO.old}</TableCell>
      <TableCell sx={sxNumberCell}>{statistics.COCO.total}</TableCell>
      <TableCell sx={sxNumberCell}>{statistics.DISE.new}</TableCell>
      <TableCell sx={sxNumberCell}>{statistics.DISE.old}</TableCell>
      <TableCell sx={sxNumberCell}>{statistics.DISE.total}</TableCell>
      <TableCell sx={sxNumberCell}>{statistics.CERTIFICATE.new}</TableCell>
      <TableCell sx={sxNumberCell}>{statistics.CERTIFICATE.old}</TableCell>
      <TableCell sx={sxNumberCell}>{statistics.CERTIFICATE.total}</TableCell>
      <TableCell sx={sxNumberCell}>{statistics.total}</TableCell>
    </TableGroupRow>
  );
});

StaffListGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  staff: PropTypes.arrayOf(StaffShape.isRequired).isRequired,
  options: OptionsShape.isRequired,
};

export default StaffListGroup;
