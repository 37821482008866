import PropTypes from 'prop-types';

import { ParentShape } from '../../shapes/user.shape';
import { IProductType } from '../../types/product-type.type';
import { IRegistrationType } from '../../types/staff.type';

export const StaffStudentShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  parent: ParentShape.isRequired,
  school: PropTypes.string.isRequired,
  schoolYear: PropTypes.string.isRequired,
  schoolLevel: PropTypes.string.isRequired,
  groupCode: PropTypes.string.isRequired,
  priority: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  productType: PropTypes.oneOf(Object.values(IProductType)).isRequired,
  registrationType: PropTypes.oneOf(Object.values(IRegistrationType)).isRequired,
  status: PropTypes.string,
});
