import React, { useContext } from 'react';

import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Route, Routes } from 'react-router-dom';

import Header from '../../components/Header';
import Main from '../../components/Main';
import Preload from '../../components/Preload';
import Sidebar from '../../components/Sidebar';
import ROUTES_LIST from '../../routes';
import UserContext from '../../user-context';
import NotFound from '../NotFound';

const drawerWidth = 270;

const Root: React.FC = React.memo(() => {
  const { user } = useContext(UserContext);
  const [open, setOpen] = React.useState(true);
  const handleToggleDrawer = () => setOpen(!open);

  return (
    <>
      {!user && <Preload />}

      {user && (
        <>
          <Header open={open} width={drawerWidth} handleToggle={handleToggleDrawer} />
          <Sidebar open={open} width={drawerWidth} handleToggle={handleToggleDrawer} />

          <Main>
            <Toolbar />

            <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
              <Routes>
                {ROUTES_LIST.map(({ name, ...route }) => (
                  <Route key={name} {...route} />
                ))}

                <Route path="*" element={<NotFound />} />
              </Routes>
            </Container>
          </Main>
        </>
      )}
    </>
  );
});

export default Root;
