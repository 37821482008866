import React from 'react';

import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import ProgressSection from '../ProgressSection';

interface IProps {
  children: React.ReactNode;
  isLoading?: boolean;
}

const PaperWrapper: React.FC<IProps> = React.memo(({ children, isLoading = false }) => (
  <Paper
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}
  >
    {isLoading && <ProgressSection />}

    {!isLoading && children}
  </Paper>
));

PaperWrapper.defaultProps = {
  isLoading: false,
};

PaperWrapper.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default PaperWrapper;
