import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import { IStaffStudent } from '../../../../types/staff-student.type';
import { OptionsShape } from '../../../Staff/shapes';
import { IOptions } from '../../../Staff/types';
import { StaffStudentShape } from '../../shapes';
import StaffStudentsListBody from '../StaffStudentsListBody';
import StaffStudentsListHead from '../StaffStudentsListHead';

interface IProps {
  students: IStaffStudent[];
  options: IOptions;
}

const StaffStudentsList: React.FC<IProps> = React.memo(({ students, options }) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <StaffStudentsListHead options={options} />
          <StaffStudentsListBody students={students} options={options} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

StaffStudentsList.propTypes = {
  students: PropTypes.arrayOf(StaffStudentShape.isRequired).isRequired,
  options: OptionsShape.isRequired,
};

export default StaffStudentsList;
