import { ApiHelper } from './api.helper';
import { IPreInscription } from '../types/pre-inscription.type';
import { HttpUtils } from '../utils/http.utils';
import { IFilters, IRequestParams } from '../views/PreInscriptions/types';

export const fetchPreInscriptions = async (filters: IFilters): Promise<IPreInscription[]> => {
  const queryParams = HttpUtils.toQueryParams<IFilters, IRequestParams>(filters);
  const url = `${ApiHelper.makeUrl('pre-inscriptions')}?${queryParams}`;
  const response = await fetch(url, ApiHelper.makeOptions());
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

export const downloadPreInscriptions = async (filters: IFilters): Promise<Blob> => {
  const queryParams = HttpUtils.toQueryParams<IFilters, IRequestParams>(filters);
  const url = `${ApiHelper.makeUrl('pre-inscriptions/export')}?${queryParams}`;

  const response = await fetch(url, ApiHelper.makeOptions());
  const data = response.blob();

  if (!response.ok) {
    const responseData = await response.json();

    throw new Error(await responseData.message);
  }

  return data;
};
