import React from 'react';

import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

const EmptyData = React.memo(() => {
  const { t } = useTranslation();

  return (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Alert severity="info">{t('general.empty_data')}</Alert>
        </Paper>
      </Grid>
    </Grid>
  );
});

export default EmptyData;
