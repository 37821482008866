import { useEffect, useState } from "react";

import { fetchDisciplines } from "../api/discipline";
import { fetchSchools } from "../api/school";
import { fetchSchoolLevels } from "../api/school-level";
import { fetchSchoolYears } from "../api/school-year";
import { fetchTaxons } from "../api/taxon";
import { IDiscipline } from "../types/discipline.type";
import { ISchoolLevel } from "../types/school-level.type";
import { ISchoolYear } from "../types/school-year.type";
import { ISchool } from "../types/school.type";
import { ITaxon } from "../types/taxon.type";

interface IDataPromise {
  key: 'school' | 'years' | 'levels' | 'disciplines' | 'taxons';
  promise: Promise<ISchool[]> | Promise<ISchoolYear[]> | Promise<ISchoolLevel[]>;
}

export const useFetchInscriptionFiltersData = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [schoolYears, setSchoolYears] = useState<ISchoolYear[]>([]);
  const [schoolLevels, setSchoolLevels] = useState<ISchoolLevel[]>([]);
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([]);
  const [taxons, setTaxons] = useState<ITaxon[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const promises: IDataPromise[] = [
        { key: 'school', promise: fetchSchools() },
        { key: 'years', promise: fetchSchoolYears() },
        { key: 'levels', promise: fetchSchoolLevels() },
        { key: 'disciplines', promise: fetchDisciplines() },
        { key: 'taxons', promise: fetchTaxons() },
      ];
      const items = await Promise.allSettled(promises.map(({ promise }) => promise));

      items.forEach((item, idx) => {
        const data = item.status === 'fulfilled' ? item.value : [];
        const { key } = promises[idx];

        switch (key) {
          case 'school':
            setSchools(data as ISchool[]);

            break;

          case 'levels':
            setSchoolLevels(data as ISchoolLevel[]);

            break;

          case 'years':
            setSchoolYears(data as ISchoolYear[]);

            break;

          case 'disciplines':
            setDisciplines(data as IDiscipline[]);

            break;

          case 'taxons':
            setTaxons(data as ITaxon[]);

            break;

          default:
            break;
        }
      });
    };

    fetchData().finally(() => setLoading(false));
  }, []);

  return { schools, schoolYears, schoolLevels, disciplines, taxons, isLoading };
};
