import { useEffect, useState } from "react";

import { fetchSchoolYears } from "../api/school-year";
import { ISchoolYear } from "../types/school-year.type";

export const useFetchSettingsFormData = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [schoolYears, setSchoolYears] = useState<ISchoolYear[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      let years: ISchoolYear[] = [];

      try {
        years = await fetchSchoolYears();
      } catch {
        /* empty */
      }

      setSchoolYears(years);
    };

    fetchData().finally(() => setLoading(false));
  }, []);

  return { schoolYears, isLoading };
};
