import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    darkBlue: Palette['primary'];
  }

  interface PaletteOptions {
    darkBlue?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    darkBlue: true;
  }
}

export const AppTheme = createTheme({
  palette: {
    darkBlue: {
      light: '#114A83',
      main: '#092948',
      dark: '#114A83',
      contrastText: '#ffffff',
    },
  },
});

export const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});
