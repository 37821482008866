import React from 'react';

import { FormControl } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import PropTypes from 'prop-types';

import { FORM_CONTROL_SX } from '../../constants/grid.constant';
import { DayjsShape } from '../../shapes/dayjs.shape';
import { ControlSx } from '../../types/control.type';

type IProps = ControlSx & {
  label?: string;
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
};

const FormDatePicker: React.FC<IProps> = React.memo(
  ({ label, controlSx = {}, onChange, value }) => (
    <Grid item>
      <FormControl
        sx={{
          ...FORM_CONTROL_SX,
          ...controlSx,
        }}
        data-testid="date-picker-test"
      >
        <DatePicker label={label} value={value} onChange={onChange} />
      </FormControl>
    </Grid>
  ),
);

FormDatePicker.defaultProps = {
  label: undefined,
  controlSx: { width: 200 },
  value: null,
};

FormDatePicker.propTypes = {
  label: PropTypes.string,
  controlSx: PropTypes.object,
  value: DayjsShape,
  onChange: PropTypes.func.isRequired,
};

export default FormDatePicker;
