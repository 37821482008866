import { useEffect, useState } from "react";

import { fetchSchools } from "../api/school";
import { fetchSchoolLevels } from "../api/school-level";
import { fetchSchoolYears } from "../api/school-year";
import { ISchoolLevel } from "../types/school-level.type";
import { ISchoolYear } from "../types/school-year.type";
import { ISchool } from "../types/school.type";

interface IDataPromise {
  key: 'school' | 'years' | 'levels';
  promise: Promise<ISchool[]> | Promise<ISchoolYear[]> | Promise<ISchoolLevel[]>;
}

export const useFetchStaffEvolutionFiltersData = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [schoolYears, setSchoolYears] = useState<ISchoolYear[]>([]);
  const [schoolLevels, setSchoolLevels] = useState<ISchoolLevel[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const promises: IDataPromise[] = [
        { key: 'school', promise: fetchSchools() },
        { key: 'years', promise: fetchSchoolYears() },
        { key: 'levels', promise: fetchSchoolLevels() },
      ];
      const items = await Promise.allSettled(promises.map(({ promise }) => promise));

      items.forEach((item, idx) => {
        const data = item.status === 'fulfilled' ? item.value : [];
        const { key } = promises[idx];

        switch (key) {
          case 'school':
            setSchools(data as ISchool[]);

            break;

          case 'levels':
            setSchoolLevels(data as ISchoolLevel[]);

            break;

          case 'years':
            setSchoolYears(data as ISchoolYear[]);

            break;

          default:
            break;
        }
      });
    };

    fetchData().finally(() => setLoading(false));
  }, []);

  return { schools, schoolYears, schoolLevels, isLoading };
};
