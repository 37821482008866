import PropTypes from 'prop-types';

import { DayjsShape } from '../../shapes/dayjs.shape';
import { IProductType } from '../../types/product-type.type';

export const StaffEvolutionStatisticShape = PropTypes.shape({
  period1: PropTypes.number.isRequired,
  period2: PropTypes.number.isRequired,
  evolution: PropTypes.number.isRequired,
});

export const StaffEvolutionShape = PropTypes.shape({
  schoolLevel: PropTypes.string.isRequired,
  groupCode: PropTypes.string.isRequired,
  [IProductType.COCO]: StaffEvolutionStatisticShape.isRequired,
  [IProductType.DISE]: StaffEvolutionStatisticShape.isRequired,
  [IProductType.CERTIFICATE]: StaffEvolutionStatisticShape.isRequired,
  total: StaffEvolutionStatisticShape.isRequired,
});

export const StaffEvolutionFiltersShape = PropTypes.shape({
  school: PropTypes.string.isRequired,
  period1SchoolYear: PropTypes.string.isRequired,
  period2SchoolYear: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  registrationType: PropTypes.string.isRequired,
  schoolLevel: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  period1DateFrom: DayjsShape.isRequired,
  period1DateTo: DayjsShape.isRequired,
  period2DateFrom: DayjsShape.isRequired,
  period2DateTo: DayjsShape.isRequired,
});
