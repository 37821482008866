import Keycloak from 'keycloak-js';

const keycloak = Keycloak({
  url: process.env.REACT_APP_OAUTH_URL,
  realm: process.env.REACT_APP_OAUTH_REALM ?? '',
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID ?? '',
});

keycloak.onTokenExpired = () => {
  keycloak.updateToken(280).catch(() => keycloak.logout());
};

export default keycloak;
