import React, { useState } from 'react';

import { SelectChangeEvent } from '@mui/material';
import { Dayjs } from 'dayjs';

export const useFilters = <F extends {}>(defaultFilters: F) => {
  const [filters, setFilters] = useState<F>(defaultFilters);

  const handleSelectChange = React.useCallback(
    <T = string>(event: SelectChangeEvent<T>) => {
      const { name, value } = event.target;

      if (!Object.keys(filters).includes(name)) {
        return;
      }

      setFilters({
        ...filters,
        [name]: value,
      });
    },
    [filters],
  );

  const handleDateChange = React.useCallback(
    (name: string, value: Dayjs | null) => {
      if (!Object.keys(filters).includes(name)) {
        return;
      }

      setFilters({
        ...filters,
        [name]: value,
      });
    },
    [filters],
  );

  const handleMultipleChoiceChange = React.useCallback(
    (name: string, value: string | string[]) => {
      if (!Object.keys(filters).includes(name)) {
        return;
      }

      setFilters({
        ...filters,
        [name]: value,
      });
    },
    [filters],
  );

  const handleCheckboxChoose = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;

      setFilters({
        ...filters,
        [name]: checked,
      });
    },
    [filters],
  );

  return {
    filters,
    setFilters,
    handleSelectChange,
    handleDateChange,
    handleMultipleChoiceChange,
    handleCheckboxChoose,
  };
};
