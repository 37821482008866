import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import { IStaffEvolution } from '../../../../types/staff-evolution.type';
import { StaffEvolutionFiltersShape, StaffEvolutionShape } from '../../shapes';
import { IFilters } from '../../types';
import StaffEvolutionListBody from '../StaffEvolutionListBody';
import StaffEvolutionListFooter from '../StaffEvolutionListFooter';
import StaffEvolutionListHead from '../StaffEvolutionListHead';

interface IProps {
  filters: IFilters;
  evolution: IStaffEvolution[];
}

const StaffEvolutionList: React.FC<IProps> = React.memo(({ filters, evolution }) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <StaffEvolutionListHead filters={filters} />

          {evolution?.length > 0 && (
            <>
              <StaffEvolutionListBody
                evolution={evolution}
                displayProductType={filters.productType}
              />

              <StaffEvolutionListFooter
                evolution={evolution}
                displayProductType={filters.productType}
              />
            </>
          )}
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

StaffEvolutionList.propTypes = {
  filters: StaffEvolutionFiltersShape.isRequired,
  evolution: PropTypes.arrayOf(StaffEvolutionShape.isRequired).isRequired,
};

export default StaffEvolutionList;
