import React from 'react';

import { Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import { ProductTypeShape } from '../../../../shapes/product-type.shape';
import { a11yProps } from '../../../../theme';
import { IProductType } from '../../../../types/product-type.type';

interface IProps {
  activeTab: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  types: IProductType[];
}

const InscriptionTabs: React.FC<IProps> = React.memo(({ types, activeTab, onChange }) => (
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <Tabs value={activeTab} onChange={onChange} aria-label="basic tabs example">
      {types.map((type, i) => (
        <Tab key={`tab_${type}`} label={type} {...a11yProps(i)} />
      ))}
    </Tabs>
  </Box>
));

InscriptionTabs.propTypes = {
  activeTab: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(ProductTypeShape.isRequired).isRequired,
};

export default InscriptionTabs;
