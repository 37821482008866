import { NumberUtils } from './number.utils';
import { IProductType } from '../types/product-type.type';
import { IStaffEvolution, IStaffEvolutionTotal } from '../types/staff-evolution.type';
import { ArrayUtils } from '../utils/array.utils';

export class StaffEvolutionHelper {
  /**
   * Make staff key
   *
   * @param evolution
   */
  static makeKey(evolution: IStaffEvolution): string {
    return [evolution.schoolLevel, evolution.groupCode]
      .map((item) => item.toLowerCase().replace(/\s/gi, '_'))
      .join('_');
  }

  /**
   * Calculate total by evolutions
   *
   * @param evolution
   */
  static calculateTotal(evolution: IStaffEvolution[]): IStaffEvolutionTotal {
    const coco = evolution.map((item) => item[IProductType.COCO]);
    const dise = evolution.map((item) => item[IProductType.DISE]);
    const certificate = evolution.map((item) => item[IProductType.CERTIFICATE]);
    const total = evolution.map((item) => item.total);

    const cocoPeriod1 = ArrayUtils.calculateTotal(coco, 'period1', true);
    const cocoPeriod2 = ArrayUtils.calculateTotal(coco, 'period2', true);
    const cocoEvolution = NumberUtils.calculatePercentage(cocoPeriod1, cocoPeriod2);

    const disePeriod1 = ArrayUtils.calculateTotal(dise, 'period1', true);
    const disePeriod2 = ArrayUtils.calculateTotal(dise, 'period2', true);
    const diseEvolution = NumberUtils.calculatePercentage(disePeriod1, disePeriod2);

    const certificatePeriod1 = ArrayUtils.calculateTotal(certificate, 'period1', true);
    const certificatePeriod2 = ArrayUtils.calculateTotal(certificate, 'period2', true);
    const certificateEvolution = NumberUtils.calculatePercentage(
      certificatePeriod1,
      certificatePeriod2,
    );

    const totalPeriod1 = ArrayUtils.calculateTotal(total, 'period1', true);
    const totalPeriod2 = ArrayUtils.calculateTotal(total, 'period2', true);
    const totalEvolution = NumberUtils.calculatePercentage(totalPeriod1, totalPeriod2);

    return {
      coco: {
        period1: cocoPeriod1,
        period2: cocoPeriod2,
        evolution: cocoEvolution,
      },
      dise: {
        period1: disePeriod1,
        period2: disePeriod2,
        evolution: diseEvolution,
      },
      certificate: {
        period1: certificatePeriod1,
        period2: certificatePeriod2,
        evolution: certificateEvolution,
      },
      total: {
        period1: totalPeriod1,
        period2: totalPeriod2,
        evolution: totalEvolution,
      },
    };
  }
}
