import PropTypes from 'prop-types';

export const DayjsShape = PropTypes.shape({
  clone: PropTypes.func.isRequired,
  isValid: PropTypes.func.isRequired,
  year: PropTypes.func.isRequired,
  month: PropTypes.func.isRequired,
  date: PropTypes.func.isRequired,
  day: PropTypes.func.isRequired,
  hour: PropTypes.func.isRequired,
  minute: PropTypes.func.isRequired,
  second: PropTypes.func.isRequired,
  millisecond: PropTypes.func.isRequired,
  set: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  subtract: PropTypes.func.isRequired,
  startOf: PropTypes.func.isRequired,
  endOf: PropTypes.func.isRequired,
  format: PropTypes.func.isRequired,
  diff: PropTypes.func.isRequired,
  valueOf: PropTypes.func.isRequired,
  unix: PropTypes.func.isRequired,
  daysInMonth: PropTypes.func.isRequired,
  toDate: PropTypes.func.isRequired,
  toJSON: PropTypes.func.isRequired,
  toISOString: PropTypes.func.isRequired,
  toString: PropTypes.func.isRequired,
  utcOffset: PropTypes.func.isRequired,
  isBefore: PropTypes.func.isRequired,
  isSame: PropTypes.func.isRequired,
  isAfter: PropTypes.func.isRequired,
  locale: PropTypes.func.isRequired,
});
