import React from 'react';

import { CircularProgress } from '@mui/material';
import Container from '@mui/material/Container';

import Main from '../Main';

const Preload = React.memo(() => (
  <Main>
    <Container
      maxWidth="xl"
      sx={{
        mt: 2,
        mb: 2,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '100%',
      }}
    >
      <CircularProgress size={100} data-testid="circular-progress-preview" />
    </Container>
  </Main>
));

export default Preload;
