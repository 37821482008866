import { useEffect, useState } from "react";

import { fetchAcademies } from "../api/academy";
import { fetchDepartments } from "../api/department";
import { fetchReasons } from "../api/reason";
import { fetchSchools } from "../api/school";
import { fetchSchoolLevels } from "../api/school-level";
import { fetchSchoolYears } from "../api/school-year";
import { fetchSituations } from "../api/situation";
import { IAcademy } from "../types/academy.type";
import { IDepartment } from "../types/department.type";
import { IReason } from "../types/reason.type";
import { ISchoolLevel } from "../types/school-level.type";
import { ISchoolYear } from "../types/school-year.type";
import { ISchool } from "../types/school.type";
import { ISituation } from "../types/situation.type";

interface IDataPromise {
  key: 'school' | 'years' | 'levels' | 'academies' | 'departments' | 'reasons' | 'situations';
  promise: Promise<ISchool[]> | Promise<ISchoolYear[]> | Promise<ISchoolLevel[]>;
}

export const useFetchPreInscriptionFiltersData = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [schoolYears, setSchoolYears] = useState<ISchoolYear[]>([]);
  const [schoolLevels, setSchoolLevels] = useState<ISchoolLevel[]>([]);
  const [academies, setAcademies] = useState<IAcademy[]>([]);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [reasons, setReasons] = useState<IReason[]>([]);
  const [situations, setSituations] = useState<ISituation[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const promises: IDataPromise[] = [
        { key: 'school', promise: fetchSchools() },
        { key: 'years', promise: fetchSchoolYears() },
        { key: 'levels', promise: fetchSchoolLevels() },
        { key: 'academies', promise: fetchAcademies() },
        { key: 'departments', promise: fetchDepartments() },
        { key: 'reasons', promise: fetchReasons() },
        { key: 'situations', promise: fetchSituations() },
      ];
      const items = await Promise.allSettled(promises.map(({ promise }) => promise));

      items.forEach((item, idx) => {
        const data = item.status === 'fulfilled' ? item.value : [];
        const { key } = promises[idx];

        switch (key) {
          case 'school':
            setSchools(data as ISchool[]);

            break;

          case 'levels':
            setSchoolLevels(data as ISchoolLevel[]);

            break;

          case 'years':
            setSchoolYears(data as ISchoolYear[]);

            break;

          case 'academies':
            setAcademies(data as IAcademy[]);

            break;

          case 'departments':
            setDepartments(data as IDepartment[]);

            break;

          case 'reasons':
            setReasons(data as IReason[]);

            break;

          case 'situations':
            setSituations(data as ISituation[]);

            break;

          default:
            break;
        }
      });
    };

    fetchData().finally(() => setLoading(false));
  }, []);

  return {
    schools,
    schoolYears,
    schoolLevels,
    academies,
    departments,
    reasons,
    situations,
    isLoading,
  };
};
