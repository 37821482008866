import { IStaff } from "../types/staff.type";

export class StaffHelper {
  /**
   * Make staff key
   *
   * @param staff
   */
  static makeKey(staff: IStaff): string {
    return [staff.school, staff.schoolYear, staff.schoolLevel, staff.groupCode].join('_');
  }
}
