import PropTypes from 'prop-types';

export const ParentShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
});

export const UserShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    filters: PropTypes.shape({
      schoolYear: PropTypes.string,
      schoolYearComparison: PropTypes.string,
      dateFrom: PropTypes.string,
      dateFromComparison: PropTypes.string,
    }),
  }).isRequired,
});
