import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import StaffEvolutionFilters from './components/StaffEvolutionFilters';
import StaffEvolutionList from './components/StaffEvolutionList';
import { DEFAULT_FILTERS } from './constants';
import { IFilters } from './types';
import { downloadStaffEvolution, fetchStaffEvolution } from '../../api/staff-evolution';
import ContentWrapper from '../../components/ContentWrapper';
import ProgressSection from '../../components/ProgressSection';
import { IStaffEvolution } from '../../types/staff-evolution.type';
import { HttpUtils } from '../../utils/http.utils';

const StaffEvolution = React.memo(() => {
  const [filters, setFilters] = useState<IFilters>(DEFAULT_FILTERS);
  const [evolution, setEvolution] = useState<IStaffEvolution[]>([]);
  const [isSubmitProcess, setSubmitProcess] = useState<boolean>(false);
  const [isExportProcess, setExportProcess] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = React.useCallback(
    (data: IFilters) => {
      setFilters(data);
      setSubmitProcess(true);

      if (!isReady) {
        setReady(true);
      }

      fetchStaffEvolution(data)
        .then((response) => {
          setEvolution(response);
        })
        .catch(() => setEvolution([]))
        .finally(() => setSubmitProcess(false));
    },
    [isReady],
  );

  const handleExport = React.useCallback(
    (data: IFilters) => {
      setFilters(data);

      setExportProcess(true);

      if (!isReady) {
        setReady(true);
      }

      downloadStaffEvolution(data)
        .then((response) => HttpUtils.handleBlob(response, 'staff_evolution.xlsx'))
        .finally(() => setExportProcess(false));
    },
    [isReady],
  );

  const renderList = React.useMemo(() => {
    if (!isReady) {
      return null;
    }

    if (isSubmitProcess) {
      return <ProgressSection mt={3} />;
    }

    return <StaffEvolutionList filters={filters} evolution={evolution} />;
  }, [evolution, filters, isReady, isSubmitProcess]);

  return (
    <ContentWrapper title={t('staff_evolution.title')}>
      <StaffEvolutionFilters
        onSubmit={handleSubmit}
        onExport={handleExport}
        isSubmitProcess={isSubmitProcess}
        isExportProcess={isExportProcess}
      />

      {renderList}
    </ContentWrapper>
  );
});

export default StaffEvolution;
