import React from 'react';

import { SelectChangeEvent } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ControlSx } from '../../types/control.type';
import { IFormSelectItem } from '../../types/form-select-item.type';
import FormSelect from '../FormSelect';

type IProps = ControlSx & {
  value: string;
  name?: string;
  onChange: <T>(event: SelectChangeEvent<T>) => void;
};

const FormRegistrationTypeSelect: React.FC<IProps> = React.memo(
  ({ name = 'registrationType', ...props }) => {
    const { t } = useTranslation();

    const REGISTRATION_TYPES: IFormSelectItem[] = [
      { code: 'new', name: t('general.new') },
      { code: 'old', name: t('general.old') },
    ];

    return (
      <FormSelect
        label={t('general.registration_type')}
        name={name}
        items={REGISTRATION_TYPES}
        useEmptyValue
        {...props}
      />
    );
  },
);

FormRegistrationTypeSelect.defaultProps = {
  controlSx: {},
  name: 'registrationType',
};

FormRegistrationTypeSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  controlSx: PropTypes.object,
  name: PropTypes.string,
};

export default FormRegistrationTypeSelect;
