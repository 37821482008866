import { isBoolean } from './type.utils';
import { API_DATE_FORMAT } from '../api/api.constants';

export class HttpUtils {
  /**
   * Convert data to query params
   *
   * @param filters
   */
  static toQueryParams = <I extends { [key: string]: any }, F extends I>(filters: I): string =>
    HttpUtils.makeQueryParams<I>(HttpUtils.preparingParams<F>(filters));

  /**
   * Make query string from object
   * @param params
   */
  static makeQueryParams<I extends { [key: string]: any }>(params: I): string {
    const queryParams = new URLSearchParams();

    Object.keys(params).forEach((key) => {
      if (params?.[key]?.length || isBoolean(params?.[key])) {
        const value = params[key];

        if (Array.isArray(value)) {
          value.forEach((item) => queryParams.append(`${key}[]`, `${item}`));
        } else {
          queryParams.append(key, `${value}`);
        }
      }
    });

    return queryParams.toString();
  }

  /**
   * Handle blob response
   *
   * @param response
   * @param filename
   */
  static handleBlob(response: Blob, filename: string): void {
    const url = window.URL.createObjectURL(response);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  }

  /**
   * Preparing request params
   *
   * @param params
   */
  static preparingParams<I>(params: { [key: string]: any }): I {
    const response = { ...params };

    Object.keys(response).forEach((key) => {
      if (typeof response[key] === 'object' && 'format' in response[key]) {
        response[key] = response[key].format(API_DATE_FORMAT) ?? null;
      }
    });

    return response as I;
  }
}
