import React from 'react';

import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

interface IProps {
  groupTitle: string;
  children: React.ReactNode;
  groupRowColSpan?: number;
}

const TableGroupRow: React.FC<IProps> = React.memo(
  ({ groupTitle, groupRowColSpan = 1, children }) => (
    <TableRow sx={{ backgroundColor: '#ccc' }}>
      <TableCell colSpan={groupRowColSpan} sx={{ fontWeight: 'bold' }}>
        {`${groupTitle}:`}
      </TableCell>

      {children}
    </TableRow>
  ),
);

TableGroupRow.defaultProps = {
  groupRowColSpan: 1,
};

TableGroupRow.propTypes = {
  groupTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  groupRowColSpan: PropTypes.number,
};

export default TableGroupRow;
