import React, { useEffect } from 'react';

import { fetchUserSettings } from '../api/user';
import keycloak from '../keycloak';
import { User } from '../model/user.model';

export const useFetchUser = () => {
  const [user, setUser] = React.useState<User | null>(null);

  useEffect(() => {
    const initUser = async () => {
      const isAuthenticated = await keycloak.init({
        onLoad: 'login-required',
        checkLoginIframe: false,
      });

      if (isAuthenticated) {
        const { id, firstName, lastName, email } = await keycloak.loadUserProfile();

        const currentUser = new User(id, firstName, lastName, email);

        if (currentUser) {
          try {
            currentUser.settings = await fetchUserSettings();
          } catch {
            /* empty */
          }
        }

        setUser(currentUser);
      } else {
        setUser(null);
      }
    };

    initUser();
  }, []);

  return { user, setUser };
};
