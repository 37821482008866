import React from 'react';

import { TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import { IStaffStudent } from '../../../../types/staff-student.type';
import { OptionsShape } from '../../../Staff/shapes';
import { IOptions } from '../../../Staff/types';
import { StaffStudentShape } from '../../shapes';
import StaffStudentsItem from '../StaffStudentsItem';

interface IProps {
  students: IStaffStudent[];
  options: IOptions;
}

const StaffStudentsListBody: React.FC<IProps> = React.memo(({ students, options }) => {
  const renderStudents = React.useMemo(() => {
    if (!students?.length) {
      return null;
    }

    return students.map((student) => (
      <StaffStudentsItem key={student.id} student={student} options={options} />
    ));
  }, [students, options]);

  return <TableBody>{renderStudents}</TableBody>;
});

StaffStudentsListBody.propTypes = {
  students: PropTypes.arrayOf(StaffStudentShape.isRequired).isRequired,
  options: OptionsShape.isRequired,
};

export default StaffStudentsListBody;
