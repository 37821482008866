export class ArrayUtils {
  /**
   * Calculate total values
   *
   * @param arr
   * @param key
   * @param toFloat
   */
  static calculateTotal = <T extends { [key: string]: any }>(
    arr: T[],
    key: keyof T,
    toFloat = false
  ): number => {
    const result = arr.reduce((total, item) => total + item[key], 0);

    if (toFloat) {
      return ArrayUtils.toFloat(result);
    }

    return result;
  };

  /**
   * Unique values by key
   *
   * @param arr
   * @param key
   */
  static uniqueValuesByKey = <
    T extends { [key: string]: any },
    R extends string | number | object | boolean
  >(
    arr: T[],
    key: string
  ): R[] => [...new Set(arr.map((item) => item[key] ?? null).filter(Boolean))];

  /**
   * Format number to 2 decimal
   *
   * @param number
   */
  static toFloat(number: number): number {
    return Math.round(number * 100) / 100;
  }
}
