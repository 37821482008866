import React from 'react';

import { SelectChangeEvent } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FormSelectItemShape } from '../../shapes/form-select-item.shape';
import { ControlSx } from '../../types/control.type';
import { IFormSelectItem } from '../../types/form-select-item.type';
import FormSelect from '../FormSelect';

type IProps = ControlSx & {
  value: string;
  name?: string;
  schools: IFormSelectItem[];
  onChange: <T>(event: SelectChangeEvent<T>) => void;
};

const FormSchoolSelect: React.FC<IProps> = React.memo(({ schools, name = 'school', ...props }) => {
  const { t } = useTranslation();

  return (
    <FormSelect label={t('general.school')} name={name} items={schools} useEmptyValue {...props} />
  );
});

FormSchoolSelect.defaultProps = {
  name: 'school',
  controlSx: {},
};

FormSchoolSelect.propTypes = {
  value: PropTypes.string.isRequired,
  schools: PropTypes.arrayOf(FormSelectItemShape.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  controlSx: PropTypes.object,
};

export default FormSchoolSelect;
