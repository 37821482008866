import React from 'react';

import { TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import { SchoolLevelHelper } from '../../../../helpers/school-level.helper';
import { StaffHelper } from '../../../../helpers/staff.helper';
import { IStaff, IStaffGroup } from '../../../../types/staff.type';
import { StringUtils } from '../../../../utils/string.utils';
import { OptionsShape, StaffShape } from '../../shapes';
import { IOptions } from '../../types';
import StaffItem from '../StaffItem';
import StaffListGroup from '../StaffListGroup';

interface IProps {
  staff: IStaff[];
  options: IOptions;
}

const StaffListBody: React.FC<IProps> = React.memo(({ staff, options }) => {
  const renderStaff = React.useMemo(() => {
    if (!staff?.length) {
      return null;
    }

    const grouped = SchoolLevelHelper.groupByCode<IStaffGroup>(staff);

    return Object.entries(grouped)
      .map(([group, groupedStaff]) => (
        <React.Fragment key={StringUtils.random(36)}>
          {groupedStaff.map((staffItem) => (
            <StaffItem key={StaffHelper.makeKey(staffItem)} staff={staffItem} options={options} />
          ))}

          <StaffListGroup groupName={group} staff={groupedStaff} options={options} />
        </React.Fragment>
      ))
      .flat();
  }, [staff, options]);

  return <TableBody>{renderStaff}</TableBody>;
});

StaffListBody.propTypes = {
  staff: PropTypes.arrayOf(StaffShape.isRequired).isRequired,
  options: OptionsShape.isRequired,
};

export default StaffListBody;
