import React from 'react';

import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

interface IProps {
  title: string;
  children: React.ReactNode;
}

const ContentWrapper: React.FC<IProps> = React.memo(({ title, children }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={12} lg={12}>
      <h1>{title}</h1>
      {children}
    </Grid>
  </Grid>
));

ContentWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContentWrapper;
