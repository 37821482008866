import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import { IPreInscription } from '../../../../types/pre-inscription.type';
import { PreInscriptionShape } from '../../shapes';
import PreInscriptionListBody from '../PreInscriptionListBody';
import PreInscriptionListHead from '../PreInscriptionListHead';

interface IProps {
  inscriptions: IPreInscription[];
}

const PreInscriptionList: React.FC<IProps> = React.memo(({ inscriptions }) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <PreInscriptionListHead />
          <PreInscriptionListBody inscriptions={inscriptions} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

PreInscriptionList.propTypes = {
  inscriptions: PropTypes.arrayOf(PreInscriptionShape.isRequired).isRequired,
};

export default PreInscriptionList;
