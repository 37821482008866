import PropTypes from 'prop-types';

import { ParentShape } from './user.shape';

export const StudentShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  parent: ParentShape.isRequired,
  status: PropTypes.string,
});
