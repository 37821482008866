import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import { ProductTypeShape } from '../../../../shapes/product-type.shape';
import { IInscription } from '../../../../types/inscription.type';
import { IProductType } from '../../../../types/product-type.type';
import { InscriptionShape } from '../../shapes';
import InscriptionListBody from '../InscriptionListBody';
import InscriptionListFooter from '../InscriptionListFooter';
import InscriptionListHead from '../InscriptionListHead';

interface IProps {
  productType: IProductType;
  inscriptions: IInscription[];
}

const InscriptionList: React.FC<IProps> = React.memo(({ inscriptions, productType }) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <InscriptionListHead productType={productType} />
          <InscriptionListBody inscriptions={inscriptions} productType={productType} />
          <InscriptionListFooter inscriptions={inscriptions} productType={productType} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

InscriptionList.propTypes = {
  productType: ProductTypeShape.isRequired,
  inscriptions: PropTypes.arrayOf(InscriptionShape.isRequired).isRequired,
};

export default InscriptionList;
