import React from 'react';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

interface IProps {
  children: React.ReactNode;
}

const Main: React.FC<IProps> = React.memo(({ children }) => (
  <Box
    component="main"
    sx={{
      backgroundColor: (theme) =>
        theme.palette.mode === 'light' ? '#e5e5e5' : theme.palette.grey[900],
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    }}
  >
    {children}
  </Box>
));

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
