import { IUser, IUserSettings } from "../types/user.type";

export class User implements IUser {
  constructor(
    public id: string = '',
    public firstName: string = '',
    public lastName: string = '',
    public email: string = '',
    public settings: IUserSettings = {},
  ) {}

  get fullName(): string {
    if (!this.firstName?.length || !this.lastName?.length) {
      return '';
    }

    return `${this.lastName} ${this.firstName}`;
  }

  get initials(): string {
    if (!this.firstName?.length || !this.lastName?.length) {
      return 'UU';
    }

    return `${this.lastName.charAt(0).toUpperCase()}${this.firstName.charAt(0).toUpperCase()}`;
  }
}
