import React, { useEffect } from 'react';

import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';

import TableGroupRow from '../../../../components/TableGroupRow';
import { ProductTypeShape } from '../../../../shapes/product-type.shape';
import { IInscription } from '../../../../types/inscription.type';
import { IProductType } from '../../../../types/product-type.type';
import { ArrayUtils } from '../../../../utils/array.utils';
import { InscriptionShape } from '../../shapes';

interface IProps {
  groupName: string;
  productType: IProductType;
  inscriptions: IInscription[];
}

const InscriptionListGroup: React.FC<IProps> = React.memo(
  ({ groupName, productType, inscriptions }) => {
    const [total, setTotal] = React.useState<number>(0);

    useEffect(() => {
      const calculatedTotal = ArrayUtils.calculateTotal(inscriptions, 'quantity');

      setTotal(calculatedTotal);
    }, [inscriptions, total]);

    return (
      <TableGroupRow
        groupTitle={groupName}
        groupRowColSpan={productType === IProductType.DISE ? 6 : 4}
      >
        <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>{total}</TableCell>
      </TableGroupRow>
    );
  },
);

InscriptionListGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  productType: ProductTypeShape.isRequired,
  inscriptions: PropTypes.arrayOf(InscriptionShape.isRequired).isRequired,
};

export default InscriptionListGroup;
