import React, { useContext, useEffect, useState } from 'react';

import { InputLabel, SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SxProps } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ISettingsForm } from './types';
import { updateUserSettings } from '../../api/user';
import ContentWrapper from '../../components/ContentWrapper';
import FormDatePicker from '../../components/FormDatePicker';
import FormSelect from '../../components/FormSelect';
import ProgressSection from '../../components/ProgressSection';
import { useFetchSettingsFormData } from '../../hooks/useFetchSettingsFormData';
import UserContext from '../../user-context';

const formFieldOptions: SxProps = { m: 1, width: 250 };

const Settings = React.memo(() => {
  const { user, setUser } = useContext(UserContext);

  const { schoolYears, isLoading } = useFetchSettingsFormData();
  const { t } = useTranslation();

  const [isReady, setReady] = useState(false);

  const [filters, setFilters] = useState<ISettingsForm>({
    schoolYear: user?.settings?.filters?.schoolYear ?? '',
    schoolYearComparison: user?.settings?.filters?.schoolYearComparison ?? '',
    dateFrom: dayjs(user?.settings?.filters?.dateFrom),
    dateFromComparison: dayjs(user?.settings?.filters?.dateFromComparison),
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const defaultYear = schoolYears[schoolYears.length - 1].code;
    const defaultFilters = Object.assign(filters, {});

    if (schoolYears.length) {
      if (!filters.schoolYear?.length) {
        defaultFilters.schoolYear = defaultYear;
      }

      if (!filters.schoolYearComparison?.length) {
        defaultFilters.schoolYearComparison = defaultYear;
      }
    }

    setFilters(defaultFilters);
    setReady(true);
  }, [filters, isLoading, schoolYears]);

  useEffect(() => {
    if (!isReady) {
      return;
    }

    updateUserSettings(filters).then((settings) => {
      if (!user) {
        return;
      }

      user.settings = settings;

      setUser(user);
    });
  }, [filters, setUser, isReady, user]);

  const handleSelectChange = React.useCallback(
    <T = string,>(event: SelectChangeEvent<T>) => {
      const { name, value } = event.target;

      if (!Object.keys(filters).includes(name)) {
        return;
      }

      setFilters({
        ...filters,
        [name]: value,
      });
    },
    [filters],
  );

  const handleDateChange = React.useCallback(
    (name: string, value: Dayjs | null) => {
      if (!Object.keys(filters).includes(name)) {
        return;
      }

      setFilters({
        ...filters,
        [name]: value,
      });
    },
    [filters],
  );

  return (
    <ContentWrapper title={t('settings.title')}>
      <Paper sx={{ p: 2 }}>
        {!isReady && <ProgressSection />}

        {isReady && (
          <form>
            <Grid container item md={6} direction="column">
              <FormSelect
                label={t('settings.school_year')}
                name="schoolYear"
                value={filters.schoolYear}
                items={schoolYears}
                onChange={handleSelectChange}
                gridProps={{
                  container: true,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                displayEmpty
                controlSx={formFieldOptions}
              />

              <FormSelect
                label={t('settings.school_year_comparison')}
                name="schoolYearComparison"
                value={filters.schoolYearComparison}
                items={schoolYears}
                onChange={handleSelectChange}
                gridProps={{
                  container: true,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                displayEmpty
                controlSx={formFieldOptions}
              />

              <Grid container item justifyContent="space-between" alignItems="center">
                <InputLabel>{t('settings.date_from')}</InputLabel>

                <FormDatePicker
                  value={filters.dateFrom}
                  onChange={(value) => handleDateChange('dateFrom', value)}
                  controlSx={formFieldOptions}
                />
              </Grid>

              <Grid container item justifyContent="space-between" alignItems="center">
                <InputLabel>{t('settings.date_from_comparison')}</InputLabel>

                <FormDatePicker
                  value={filters.dateFromComparison}
                  onChange={(value) => handleDateChange('dateFromComparison', value)}
                  controlSx={formFieldOptions}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
    </ContentWrapper>
  );
});

export default Settings;
