import keycloak from '../keycloak';

export class ApiHelper {
  /**
   * Make API request url
   * @param path
   */
  static makeUrl(path: string): string {
    return `${process.env.REACT_APP_API_URL}/${path}`;
  }

  /**
   * Make API request options
   */
  static makeOptions(): Partial<RequestInit> {
    return {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json',
      },
    };
  }
}
