import { fetchGet } from './api';
import { ApiHelper } from './api.helper';
import { IUserSettings } from '../types/user.type';
import { HttpUtils } from '../utils/http.utils';
import { ISettingsForm } from '../views/Settings/types';

export const fetchUserSettings = () => fetchGet<IUserSettings>('user/settings');

export const updateUserSettings = async (filters: ISettingsForm): Promise<IUserSettings> => {
  const body = {
    filters: HttpUtils.preparingParams<IUserSettings>(filters),
  };

  const response = await fetch(ApiHelper.makeUrl('user/settings'), {
    ...ApiHelper.makeOptions(),
    method: 'PATCH',
    body: JSON.stringify(body),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};
