import React from 'react';

import { TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import { StatusHelper } from '../../../../helpers/status.helper';
import { IStatusStatistics } from '../../../../types/status.type';
import { StatusStatisticsShape } from '../../shapes';
import StatusListItem from '../StatusListItem';

interface IProps {
  statistics: IStatusStatistics[];
  statuses: string[];
}

const StatusListBody: React.FC<IProps> = React.memo(({ statistics, statuses: uniqueStatuses }) => {
  const renderList = React.useMemo(() => {
    if (!statistics?.length) {
      return null;
    }

    const grouped = StatusHelper.groupStatisticsBySchool(statistics);

    return Object.entries(grouped)
      .map(([school, items]) => (
        <StatusListItem key={school} school={school} statistics={items} statuses={uniqueStatuses} />
      ))
      .flat();
  }, [statistics, uniqueStatuses]);

  return <TableBody>{renderList}</TableBody>;
});

StatusListBody.propTypes = {
  statistics: PropTypes.arrayOf(StatusStatisticsShape.isRequired).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default StatusListBody;
