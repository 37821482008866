import { useEffect, useState } from 'react';

import { fetchPartnerships } from '../api/partnership';
import { fetchSchools } from '../api/school';
import { fetchSchoolLevels } from '../api/school-level';
import { fetchSchoolYears } from '../api/school-year';
import { fetchStatuses } from '../api/status';
import { IPartnership } from '../types/partnership.type';
import { ISchoolLevel } from '../types/school-level.type';
import { ISchoolYear } from '../types/school-year.type';
import { ISchool } from '../types/school.type';
import { IStatus } from '../types/status.type';

interface IDataPromise {
  key: 'school' | 'years' | 'levels' | 'partnerships' | 'statuses';
  promise:
    | Promise<ISchool[]>
    | Promise<ISchoolYear[]>
    | Promise<ISchoolLevel[]>
    | Promise<IStatus[]>;
}

export const useFetchStaffFiltersData = (useStatuses = false) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [schoolYears, setSchoolYears] = useState<ISchoolYear[]>([]);
  const [schoolLevels, setSchoolLevels] = useState<ISchoolLevel[]>([]);
  const [partnerships, setPartnerships] = useState<IPartnership[]>([]);
  const [statuses, setStatuses] = useState<IStatus[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const promises: IDataPromise[] = [
        { key: 'school', promise: fetchSchools() },
        { key: 'years', promise: fetchSchoolYears() },
        { key: 'levels', promise: fetchSchoolLevels() },
        { key: 'partnerships', promise: fetchPartnerships() },
        { key: 'statuses', promise: fetchStatuses() },
      ];
      const items = await Promise.allSettled(promises.map(({ promise }) => promise));

      items.forEach((item, idx) => {
        const data = item.status === 'fulfilled' ? item.value : [];
        const { key } = promises[idx];

        switch (key) {
          case 'school':
            setSchools(data as ISchool[]);

            break;

          case 'levels':
            setSchoolLevels(data as ISchoolLevel[]);

            break;

          case 'years':
            setSchoolYears(data as ISchoolYear[]);

            break;

          case 'partnerships':
            setPartnerships(data as IPartnership[]);

            break;

          case 'statuses':
            if (useStatuses) {
              setStatuses(data as IStatus[]);
            }

            break;

          default:
            break;
        }
      });
    };

    fetchData().finally(() => setLoading(false));
  }, [useStatuses]);

  return { schools, schoolYears, schoolLevels, partnerships, statuses, isLoading };
};
