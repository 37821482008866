import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import { IStaff } from '../../../../types/staff.type';
import { OptionsShape, StaffShape } from '../../shapes';
import { IOptions } from '../../types';
import StaffListBody from '../StaffListBody';
import StaffListFooter from '../StaffListFooter';
import StaffListHead from '../StaffListHead';

interface IProps {
  staff: IStaff[];
  options: IOptions;
}

const StaffList: React.FC<IProps> = React.memo(({ staff, options }) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <StaffListHead options={options} />
          <StaffListBody staff={staff} options={options} />
          <StaffListFooter staff={staff} options={options} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

StaffList.propTypes = {
  staff: PropTypes.arrayOf(StaffShape.isRequired).isRequired,
  options: OptionsShape.isRequired,
};

export default StaffList;
