import { API_DATE_FORMAT } from '../api/api.constants';
import { IFilters } from '../views/Staff/types';

export class StaffStudentHelper {
  /**
   * Make export filename
   *
   * @param filters
   */
  static makeExportFilename(filters: IFilters): string {
    const parts = ['staff_details'];

    if (filters.dateFrom) {
      parts.push(filters.dateFrom.format(API_DATE_FORMAT));
    }

    if (filters.dateTo) {
      parts.push(filters.dateTo.format(API_DATE_FORMAT));
    }

    return `${parts.join('_')}.xlsx`;
  }
}
