import React from 'react';

import { FormControl, InputLabel, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SelectProps } from '@mui/material/Select/Select';
import PropTypes from 'prop-types';

import { FORM_CONTROL_SX } from '../../constants/grid.constant';
import { FormSelectItemShape } from '../../shapes/form-select-item.shape';
import { ControlSx } from '../../types/control.type';
import { IFormSelectItem } from '../../types/form-select-item.type';

type IProps = SelectProps &
  ControlSx & {
    label: string;
    name: string;
    items: IFormSelectItem[];
    onChoose: (value: string[]) => void;
    children?: React.ReactNode;
  };

const FormSelectMultiple: React.FC<IProps> = React.memo(
  ({ children, label, items, onChoose, name, controlSx = {}, ...props }) => {
    const handleMultipleChoiceChange = React.useCallback(
      (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { options } = event.target;

        const value: string[] = [];

        for (let i = 0, l = options.length; i < l; i += 1) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }

        onChoose(value);
      },
      [onChoose],
    );

    return (
      <Grid item sx={{ alignSelf: 'flex-start' }}>
        <FormControl
          sx={{
            ...FORM_CONTROL_SX,
            ...controlSx,
          }}
        >
          <InputLabel shrink htmlFor={`select-multiple-${name}`}>
            {label}
          </InputLabel>

          <Select
            name={name}
            label={label}
            multiple
            native
            // @ts-ignore Typings are not considering `native`
            onChange={handleMultipleChoiceChange}
            inputProps={{
              id: `select-multiple-${name}`,
            }}
            data-testid={`select-multiple-test-${name}`}
            {...props}
          >
            {items.map((item) => (
              <option key={item.code} value={item.code}>
                {item.name ?? item.code}
              </option>
            ))}
          </Select>

          {children}
        </FormControl>
      </Grid>
    );
  },
);

FormSelectMultiple.defaultProps = {
  children: undefined,
  controlSx: {},
};

FormSelectMultiple.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(FormSelectItemShape.isRequired).isRequired,
  onChoose: PropTypes.func.isRequired,
  children: PropTypes.node,
  controlSx: PropTypes.object,
};

export default FormSelectMultiple;
