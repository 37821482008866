import React from 'react';

import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { StaffEvolutionFiltersShape } from '../../shapes';
import { IFilters } from '../../types';

interface IProps {
  filters: IFilters;
  period1Label: string;
  period2Label: string;
}

const StaffEvolutionListHeadPeriod: React.FC<IProps> = React.memo(
  ({ filters, period1Label, period2Label }) => {
    const { t } = useTranslation();

    return (
      <>
        <TableCell>
          <div>{period1Label}</div>
          <div>{filters.period1SchoolYear}</div>
        </TableCell>

        <TableCell>
          <div>{period2Label}</div>
          <div>{filters.period2SchoolYear}</div>
        </TableCell>

        <TableCell>{t('staff_evolution.evolution')}</TableCell>
      </>
    );
  }
);

StaffEvolutionListHeadPeriod.propTypes = {
  filters: StaffEvolutionFiltersShape.isRequired,
  period1Label: PropTypes.string.isRequired,
  period2Label: PropTypes.string.isRequired,
};

export default StaffEvolutionListHeadPeriod;
