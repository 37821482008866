import React from 'react';

import { TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import { SchoolLevelHelper } from '../../../../helpers/school-level.helper';
import { ProductTypeShape } from '../../../../shapes/product-type.shape';
import { IInscription, IInscriptionGroup } from '../../../../types/inscription.type';
import { IProductType } from '../../../../types/product-type.type';
import { StringUtils } from '../../../../utils/string.utils';
import { InscriptionShape } from '../../shapes';
import InscriptionListGroup from '../InscriptionListGroup';
import InscriptionListItem from '../InscriptionListItem';

const makeKey = (inscription: IInscription) =>
  [inscription.school, inscription.schoolLevel].join('_');

interface IProps {
  productType: IProductType;
  inscriptions: IInscription[];
}

const InscriptionListBody: React.FC<IProps> = React.memo(({ inscriptions, productType }) => {
  const renderStaff = React.useMemo(() => {
    if (!inscriptions?.length) {
      return null;
    }

    const grouped = SchoolLevelHelper.groupByCode<IInscriptionGroup>(inscriptions);

    return Object.entries(grouped)
      .map(([group, groupedInscriptions]) => (
        <React.Fragment key={StringUtils.random(36)}>
          {groupedInscriptions.map((inscription) => (
            <InscriptionListItem
              key={makeKey(inscription)}
              inscription={inscription}
              productType={productType}
            />
          ))}

          <InscriptionListGroup
            groupName={group}
            productType={productType}
            inscriptions={groupedInscriptions}
          />
        </React.Fragment>
      ))
      .flat();
  }, [inscriptions, productType]);

  return <TableBody>{renderStaff}</TableBody>;
});

InscriptionListBody.propTypes = {
  productType: ProductTypeShape.isRequired,
  inscriptions: PropTypes.arrayOf(InscriptionShape.isRequired).isRequired,
};

export default InscriptionListBody;
