import React, { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import FormDatePicker from '../../../../components/FormDatePicker';
import FormHandleControl from '../../../../components/FormHandleControl';
import FormProductTypeSelect from '../../../../components/FormProductTypeSelect';
import FormRegistrationTypeSelect from '../../../../components/FormRegistrationTypeSelect';
import FormSchoolLevelSelect from '../../../../components/FormSchoolLevelSelect';
import FormSchoolSelect from '../../../../components/FormSchoolSelect';
import FormSelect from '../../../../components/FormSelect';
import PaperWrapper from '../../../../components/PaperWrapper';
import { useFetchDefaultFiltersData } from '../../../../hooks/useFetchDefaultFiltersData';
import { useFetchStaffEvolutionFiltersData } from '../../../../hooks/useFetchStaffEvolutionFiltersData';
import { useFilters } from '../../../../hooks/useFilters';
import { FilterListShape } from '../../../../shapes/filter-list.shape';
import { IFilterList } from '../../../../types/filter-list.type';
import { IFilters } from '../../types';

const StaffEvolutionFilters: React.FC<IFilterList<IFilters>> = React.memo(
  ({ onSubmit, onExport, isSubmitProcess, isExportProcess }) => {
    const { t } = useTranslation();

    const { schools, schoolLevels, schoolYears, isLoading } = useFetchStaffEvolutionFiltersData();

    const defaultFilters = useFetchDefaultFiltersData<IFilters>('staffEvolution');

    const {
      filters,
      setFilters,
      handleSelectChange,
      handleDateChange,
      handleMultipleChoiceChange,
    } = useFilters<IFilters>(defaultFilters);

    useEffect(() => {
      if (
        isLoading ||
        filters.period1SchoolYear?.length ||
        filters.period2SchoolYear?.length ||
        !schoolYears.length
      ) {
        return;
      }

      setFilters({
        ...filters,
        period1SchoolYear: schoolYears[schoolYears.length - 1].code,
        period2SchoolYear: schoolYears[schoolYears.length - 2].code,
      });
    }, [filters, isLoading, schoolYears, setFilters]);

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();

      if (isLoading) {
        return;
      }

      onSubmit(filters);
    };

    const isValid = React.useMemo(() => {
      if (!filters.period1SchoolYear.length || !filters.period2SchoolYear.length) {
        return false;
      }

      if (!filters.period1DateFrom?.isValid() || !filters.period2DateFrom?.isValid()) {
        return false;
      }

      return filters.period1DateTo?.isValid() && filters.period2DateTo?.isValid();
    }, [filters]);

    return (
      <PaperWrapper isLoading={isLoading}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} direction="row" alignItems="center">
            <FormSelect
              label={t('staff_evolution.school_year_period1')}
              name="period1SchoolYear"
              value={filters.period1SchoolYear}
              items={schoolYears}
              onChange={handleSelectChange}
            />

            <FormDatePicker
              label={t('staff_evolution.date_from_period1')}
              value={filters.period1DateFrom}
              onChange={(value) => handleDateChange('period1DateFrom', value)}
            />

            <FormDatePicker
              label={t('staff_evolution.date_to_period1')}
              value={filters.period1DateTo}
              onChange={(value) => handleDateChange('period1DateTo', value)}
            />

            <FormSelect
              label={t('staff_evolution.school_year_period2')}
              name="period2SchoolYear"
              value={filters.period2SchoolYear}
              items={schoolYears}
              onChange={handleSelectChange}
            />

            <FormDatePicker
              label={t('staff_evolution.date_from_period2')}
              value={filters.period2DateFrom}
              onChange={(value) => handleDateChange('period2DateFrom', value)}
            />

            <FormDatePicker
              label={t('staff_evolution.date_to_period2')}
              value={filters.period2DateTo}
              onChange={(value) => handleDateChange('period2DateTo', value)}
            />
          </Grid>

          <Grid container spacing={1} direction="row" alignItems="flex-start">
            <FormSchoolLevelSelect
              value={filters.schoolLevel}
              schoolLevels={schoolLevels}
              onChange={(value) => handleMultipleChoiceChange('schoolLevel', value)}
            />

            <FormSchoolSelect
              onChange={handleSelectChange}
              schools={schools}
              value={filters.school}
            />

            <FormProductTypeSelect value={filters.productType} onChange={handleSelectChange} />

            <FormRegistrationTypeSelect
              value={filters.registrationType}
              onChange={handleSelectChange}
            />
          </Grid>

          <FormHandleControl
            isSubmitProcess={isSubmitProcess}
            isExportProcess={isExportProcess}
            isDisabled={!isValid}
            onExport={() => onExport(filters)}
          />
        </form>
      </PaperWrapper>
    );
  },
);

StaffEvolutionFilters.propTypes = FilterListShape;

export default StaffEvolutionFilters;
