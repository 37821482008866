import React from 'react';

import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { SystemProps } from '@mui/system';

const ProgressSection = React.memo((props: SystemProps<Theme>) => (
  <Grid container direction="column" justifyContent="center" alignItems="center" {...props}>
    <Grid item>
      <CircularProgress data-testid="progress-section-loader" />
    </Grid>
  </Grid>
));

export default ProgressSection;
