import { createContext } from 'react';

import { User } from './model/user.model';

type IUserContext = {
  user: User | null;
  setUser: (user: User) => void;
};

const UserContext = createContext<IUserContext>({
  user: null,
  setUser: () => null,
});

export default UserContext;
