import React from 'react';

import { TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import { SchoolLevelHelper } from '../../../../helpers/school-level.helper';
import { StaffEvolutionHelper } from '../../../../helpers/staff-evolution.helper';
import { IStaffEvolution, IStaffEvolutionGroup } from '../../../../types/staff-evolution.type';
import { StringUtils } from '../../../../utils/string.utils';
import { StaffEvolutionShape } from '../../shapes';
import StaffEvolutionListGroup from '../StaffEvolutionListGroup';
import StaffEvolutionListItem from '../StaffEvolutionListItem';

interface IProps {
  evolution: IStaffEvolution[];
  displayProductType?: string;
}

const StaffEvolutionListBody: React.FC<IProps> = React.memo(({ evolution, displayProductType }) => {
  const renderList = React.useMemo(() => {
    if (!evolution?.length) {
      return null;
    }

    const grouped = SchoolLevelHelper.groupByCode<IStaffEvolutionGroup>(evolution);

    return Object.entries(grouped).map(([group, groupedEvolution]) => (
      <React.Fragment key={StringUtils.random(36)}>
        {groupedEvolution.map((item) => (
          <StaffEvolutionListItem
            key={StaffEvolutionHelper.makeKey(item)}
            evolution={item}
            displayProductType={displayProductType}
          />
        ))}

        <StaffEvolutionListGroup
          groupName={group}
          evolution={groupedEvolution}
          displayProductType={displayProductType}
        />
      </React.Fragment>
    ));
  }, [displayProductType, evolution]);

  return <TableBody>{renderList}</TableBody>;
});

StaffEvolutionListBody.defaultProps = {
  displayProductType: undefined,
};

StaffEvolutionListBody.propTypes = {
  evolution: PropTypes.arrayOf(StaffEvolutionShape.isRequired).isRequired,
  displayProductType: PropTypes.string,
};

export default StaffEvolutionListBody;
