import React from 'react';

import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TableTotalFooter from '../../../../components/TableTotalFooter';
import { IRegistrationType, IStaff } from '../../../../types/staff.type';
import { ArrayUtils } from '../../../../utils/array.utils';
import { OptionsShape, StaffShape } from '../../shapes';
import { IOptions } from '../../types';

interface IProps {
  staff: IStaff[];
  options: IOptions;
}

const StaffListFooter: React.FC<IProps> = React.memo(({ staff, options }) => {
  const { t } = useTranslation();

  const renderTotalRow = React.useMemo(() => {
    const coco = staff.map(({ COCO }) => COCO);
    const dise = staff.map(({ DISE }) => DISE);
    const certificate = staff.map(({ CERTIFICATE }) => CERTIFICATE);

    const cocoTotal = ArrayUtils.calculateTotal(coco, 'total');
    const diseTotal = ArrayUtils.calculateTotal(dise, 'total');
    const certificateTotal = ArrayUtils.calculateTotal(certificate, 'total');

    return (
      <>
        <TableCell sx={{ fontWeight: 'bold' }}>{t(`general.total`)}</TableCell>
        <TableCell />
        <TableCell />
        {options.displayPartnership && <TableCell />}
        <TableCell>{ArrayUtils.calculateTotal(coco, IRegistrationType.new)}</TableCell>
        <TableCell>{ArrayUtils.calculateTotal(coco, IRegistrationType.old)}</TableCell>
        <TableCell>{cocoTotal}</TableCell>
        <TableCell>{ArrayUtils.calculateTotal(dise, IRegistrationType.new)}</TableCell>
        <TableCell>{ArrayUtils.calculateTotal(dise, IRegistrationType.old)}</TableCell>
        <TableCell>{diseTotal}</TableCell>
        <TableCell>{ArrayUtils.calculateTotal(certificate, IRegistrationType.new)}</TableCell>
        <TableCell>{ArrayUtils.calculateTotal(certificate, IRegistrationType.old)}</TableCell>
        <TableCell>{certificateTotal}</TableCell>
        <TableCell>{ArrayUtils.calculateTotal(staff, 'total')}</TableCell>
      </>
    );
  }, [staff, options, t]);

  return <TableTotalFooter>{renderTotalRow}</TableTotalFooter>;
});

StaffListFooter.propTypes = {
  staff: PropTypes.arrayOf(StaffShape.isRequired).isRequired,
  options: OptionsShape.isRequired,
};

export default StaffListFooter;
