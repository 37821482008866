import React from 'react';

import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ProgressButton from '../ProgressButton';

interface IProps {
  isSubmitProcess: boolean;
  isExportProcess?: boolean;
  isDisabled?: boolean;
  onExport?: () => void;
  submitTitle?: string;
  exportTitle?: string;
}

const FormHandleControl: React.FC<IProps> = React.memo(
  ({
    isSubmitProcess,
    isExportProcess = false,
    onExport,
    isDisabled = false,
    submitTitle,
    exportTitle,
  }) => {
    const { t } = useTranslation();

    return (
      <Grid container spacing={1} px={1} pt={1}>
        <Grid item>
          <ProgressButton
            title={submitTitle ?? t('general.filter')}
            type="submit"
            isLoading={isSubmitProcess}
            isDisabled={isExportProcess || isDisabled}
          />
        </Grid>

        <Grid item>
          <ProgressButton
            title={exportTitle ?? t('general.export')}
            isLoading={isExportProcess}
            isDisabled={isSubmitProcess || isDisabled}
            onClick={onExport}
          />
        </Grid>
      </Grid>
    );
  },
);

FormHandleControl.propTypes = {
  isSubmitProcess: PropTypes.bool.isRequired,
  isExportProcess: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onExport: PropTypes.func,
  submitTitle: PropTypes.string,
  exportTitle: PropTypes.string,
};

FormHandleControl.defaultProps = {
  isExportProcess: false,
  isDisabled: false,
  onExport: undefined,
  submitTitle: undefined,
  exportTitle: undefined,
};

export default FormHandleControl;
