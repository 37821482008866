import React from 'react';

import { Link } from '@mui/material';

const Logo = React.memo(() => (
  <Link href="/">
    <img data-testid="app-logo" src="/assets/logo.png" alt="Logo" width={200} />
  </Link>
));

export default Logo;
