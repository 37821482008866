import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import StaffStudentsList from './components/StaffStudentsList';
import { downloadStaffStudents, fetchStaffStudents } from '../../api/staff-student';
import ContentWrapper from '../../components/ContentWrapper';
import ProgressSection from '../../components/ProgressSection';
import { StaffStudentHelper } from '../../helpers/staff-student.helper';
import { IStaffStudent } from '../../types/staff-student.type';
import { HttpUtils } from '../../utils/http.utils';
import StaffFilters from '../Staff/components/StaffFilters';
import { IFilters, IOptions } from '../Staff/types';

const StaffStudents = React.memo(() => {
  const [students, setStudents] = useState<IStaffStudent[]>([]);
  const [isSubmitProcess, setSubmitProcess] = useState<boolean>(false);
  const [isExportProcess, setExportProcess] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);
  const [options, setOptions] = useState<IOptions>({
    displayPartnership: false,
  });

  const { t } = useTranslation();

  const handleSubmit = React.useCallback(
    (filters: IFilters) => {
      setSubmitProcess(true);

      setOptions({
        ...options,
        displayPartnership: filters.partnerships.length > 0,
      });

      if (!isReady) {
        setReady(true);
      }

      fetchStaffStudents(filters)
        .then((response) => {
          setStudents(response);
        })
        .catch(() => setStudents([]))
        .finally(() => setSubmitProcess(false));
    },
    [isReady, options],
  );

  const handleExport = React.useCallback(
    (filters: IFilters) => {
      setExportProcess(true);

      if (!isReady) {
        setReady(true);
      }

      downloadStaffStudents(filters)
        .then((response) =>
          HttpUtils.handleBlob(response, StaffStudentHelper.makeExportFilename(filters)),
        )
        .finally(() => setExportProcess(false));
    },
    [isReady],
  );

  const renderList = React.useMemo(() => {
    if (!isReady) {
      return null;
    }

    if (isSubmitProcess) {
      return <ProgressSection mt={3} />;
    }

    return <StaffStudentsList students={students} options={options} />;
  }, [isReady, isSubmitProcess, students, options]);

  return (
    <ContentWrapper title={t('staff_students.title')}>
      <StaffFilters
        onSubmit={handleSubmit}
        onExport={handleExport}
        isSubmitProcess={isSubmitProcess}
        isExportProcess={isExportProcess}
        useStudentStatusFilter
      />

      {renderList}
    </ContentWrapper>
  );
});

export default StaffStudents;
