import { IProductType } from "./product-type.type";
import { RecordString } from "./record-string.type";
import { ITotal } from "./total.type";

export type IStaff = RecordString<'school' | 'schoolYear' | 'schoolLevel' | 'groupCode'> & {
  priority: number | null;
  partnership?: string;
} & Record<IProductType, IStaffProductType> &
  ITotal;

export type IStaffGroup = {
  [key: string]: IStaff[];
};

export enum IRegistrationType {
  new = 'new',
  old = 'old',
}

export type IStaffProductType = Record<IRegistrationType, number> & ITotal;
