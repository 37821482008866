import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import PreInscriptionFilters from './components/PreInscriptionFilters';
import PreInscriptionList from './components/PreInscriptionList';
import { IFilters } from './types';
import { downloadPreInscriptions, fetchPreInscriptions } from '../../api/pre-inscription';
import ContentWrapper from '../../components/ContentWrapper';
import ProgressSection from '../../components/ProgressSection';
import { IPreInscription } from '../../types/pre-inscription.type';
import { HttpUtils } from '../../utils/http.utils';

const PreInscriptions = React.memo(() => {
  const [inscriptions, setInscriptions] = useState<IPreInscription[]>([]);
  const [isSubmitProcess, setSubmitProcess] = useState<boolean>(false);
  const [isExportProcess, setExportProcess] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = React.useCallback(
    (filters: IFilters) => {
      setSubmitProcess(true);

      if (!isReady) {
        setReady(true);
      }

      fetchPreInscriptions(filters)
        .then((response) => {
          setInscriptions(response);
        })
        .catch(() => setInscriptions([]))
        .finally(() => setSubmitProcess(false));
    },
    [isReady],
  );

  const handleExport = React.useCallback(
    (filters: IFilters) => {
      setExportProcess(true);

      if (!isReady) {
        setReady(true);
      }

      downloadPreInscriptions(filters)
        .then((response) => HttpUtils.handleBlob(response, 'pre_inscriptions.xlsx'))
        .finally(() => setExportProcess(false));
    },
    [isReady],
  );

  const renderList = React.useMemo(() => {
    if (!isReady) {
      return null;
    }

    if (isSubmitProcess) {
      return <ProgressSection mt={3} />;
    }

    return <PreInscriptionList inscriptions={inscriptions} />;
  }, [inscriptions, isReady, isSubmitProcess]);

  return (
    <ContentWrapper title={t('pre_inscriptions.title')}>
      <PreInscriptionFilters
        onSubmit={handleSubmit}
        onExport={handleExport}
        isSubmitProcess={isSubmitProcess}
        isExportProcess={isExportProcess}
      />

      {renderList}
    </ContentWrapper>
  );
});

export default PreInscriptions;
