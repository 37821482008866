export const CHART_COLOR = {
  red: {
    background: 'rgba(255, 99, 132, 0.2)',
    default: 'rgba(255, 99, 132, 0.5)',
    border: 'rgba(255, 99, 132, 1)',
  },
  blue: {
    background: 'rgba(54, 162, 235, 0.2)',
    default: 'rgba(54, 162, 235, 0.5)',
    border: 'rgba(54, 162, 235, 1)',
  },
  yellow: {
    background: 'rgba(255, 206, 86, 0.2)',
    default: 'rgba(255, 206, 86, 0.5)',
    border: 'rgba(255, 206, 86, 1)',
  },
  green: {
    background: 'rgba(75, 192, 192, 0.2)',
    default: 'rgba(75, 192, 192, 0.5)',
    border: 'rgba(75, 192, 192, 1)',
  },
  purple: {
    background: 'rgba(153, 102, 255, 0.2)',
    default: 'rgba(153, 102, 255, 0.5)',
    border: 'rgba(153, 102, 255, 1)',
  },
  orange: {
    background: 'rgba(255, 159, 64, 0.2)',
    default: 'rgba(255, 159, 64, 0.5)',
    border: 'rgba(255, 159, 64, 1)',
  },
};
