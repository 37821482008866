import React from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';
import { BrowserRouter } from 'react-router-dom';

import { useFetchUser } from './hooks/useFetchUser';
import { AppTheme } from './theme';
import UserContext from './user-context';
import Root from './views/Root';

const App = React.memo(() => {
  const user = useFetchUser();

  return (
    <UserContext.Provider value={user}>
      <React.StrictMode>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <ThemeProvider theme={AppTheme}>
              <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <Root />
              </Box>
            </ThemeProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </React.StrictMode>
    </UserContext.Provider>
  );
});

export default App;
