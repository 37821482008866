import { IGroupCode } from '../types/group-code.type';

export class SchoolLevelHelper {
  /**
   * Get grouped items by school level code
   *
   * @param items
   */
  static groupByCode<I>(items: IGroupCode[]): I {
    const grouped: { [key: string]: any[] } = {};

    items.forEach((item) => {
      const group = item.groupCode;

      if (!grouped[group]) {
        grouped[group] = [];
      }

      grouped[group].push(item);
    });

    return grouped as I;
  }
}
