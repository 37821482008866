import React from 'react';

import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { IStatisticsStaff } from '../../../../types/statistics.type';
import { StatisticsStaffShape } from '../../shapes';
import StatisticsStaffChart from '../StatisticsStaffChart';

interface IProps {
  statistics: IStatisticsStaff;
}

const StatisticsStaff: React.FC<IProps> = React.memo(({ statistics }) => {
  const { t } = useTranslation();
  const charts = Object.entries(statistics).map(([school, data]) => (
    <Grid key={`statistics_chart_${school}`} item md={3}>
      <StatisticsStaffChart school={school} data={data} />
    </Grid>
  ));

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ pl: 3, pr: 3 }}
    >
      <Grid item md={12} textAlign="center">
        <h4>{t('dashboard.statistics.staff')}</h4>
      </Grid>

      {charts}
    </Grid>
  );
});

StatisticsStaff.propTypes = {
  statistics: PropTypes.objectOf(StatisticsStaffShape.isRequired).isRequired,
};

export default StatisticsStaff;
