import React from 'react';

import { ChartData } from 'chart.js';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import { CHART_COLOR } from '../../../../constants/chart.constant';
import { IStatisticsEvolutionPeriod } from '../../../../types/statistics.type';

interface IProps {
  school: string;
  data: IStatisticsEvolutionPeriod;
}

const StatisticsEvolutionChart: React.FC<IProps> = React.memo(({ school, data }) => {
  const labels = [''];

  const chartData: ChartData<'bar'> = {
    labels,
    datasets: [
      {
        label: 'Period 1',
        data: [data.period1],
        backgroundColor: CHART_COLOR.yellow.default,
      },
      {
        label: 'Period 2',
        data: [data.period2],
        backgroundColor: CHART_COLOR.green.default,
      },
    ],
  };

  return (
    <Bar
      options={{
        plugins: {
          title: {
            display: true,
            text: school.toUpperCase(),
            align: 'center',
          },
        },
      }}
      data={chartData}
    />
  );
});

StatisticsEvolutionChart.propTypes = {
  school: PropTypes.string.isRequired,
  data: PropTypes.shape({
    period1: PropTypes.number.isRequired,
    period2: PropTypes.number.isRequired,
  }).isRequired,
};

export default StatisticsEvolutionChart;
