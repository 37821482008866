import PropTypes from 'prop-types';

export const StaffProductTypeShape = PropTypes.shape({
  new: PropTypes.number.isRequired,
  old: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
});

export const StaffShape = PropTypes.shape({
  school: PropTypes.string.isRequired,
  schoolYear: PropTypes.string.isRequired,
  schoolLevel: PropTypes.string.isRequired,
  groupCode: PropTypes.string.isRequired,
  priority: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  COCO: StaffProductTypeShape.isRequired,
  DISE: StaffProductTypeShape.isRequired,
  CERTIFICATE: StaffProductTypeShape.isRequired,
});

export const OptionsShape = PropTypes.shape({
  displayPartnership: PropTypes.bool.isRequired,
});
