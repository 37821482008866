import dayjs from 'dayjs';

import { IFilters } from './types';

export const DEFAULT_FILTERS: IFilters = {
  period1SchoolYear: '',
  period1DateFrom: dayjs().subtract(1, 'month'),
  period1DateTo: dayjs(),
  period2SchoolYear: '',
  period2DateFrom: dayjs().subtract(1, 'year').subtract(1, 'month'),
  period2DateTo: dayjs().subtract(1, 'year'),
  productType: '',
  registrationType: '',
  school: '',
  schoolLevel: [],
};
