import React from 'react';

import { TableFooter, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

interface IProps {
  children: React.ReactNode;
}

const TableTotalFooter: React.FC<IProps> = React.memo(({ children }) => (
  <TableFooter>
    <TableRow sx={{ '& td:not(:first-of-type)': { textAlign: 'right', fontWeight: 'bold' } }}>
      {children}
    </TableRow>
  </TableFooter>
));

TableTotalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableTotalFooter;
