import React from 'react';

import { TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import { IPreInscription } from '../../../../types/pre-inscription.type';
import { PreInscriptionShape } from '../../shapes';
import PreInscriptionListItem from '../PreInscriptionListItem';

interface IProps {
  inscriptions: IPreInscription[];
}

const PreInscriptionListBody: React.FC<IProps> = React.memo(({ inscriptions }) => (
  <TableBody>
    {inscriptions.map((inscription) => (
      <PreInscriptionListItem key={inscription.id} inscription={inscription} />
    ))}
  </TableBody>
));

PreInscriptionListBody.propTypes = {
  inscriptions: PropTypes.arrayOf(PreInscriptionShape.isRequired).isRequired,
};

export default PreInscriptionListBody;
