import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { OptionsShape } from '../../shapes';
import { IOptions } from '../../types';

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

interface IProps {
  options: IOptions;
}

const StaffListHead: React.FC<IProps> = React.memo(({ options }) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow sx={sxTableHead}>
        <TableCell rowSpan={3}>{t(`general.school`)}</TableCell>
        <TableCell rowSpan={3}>{t(`general.school_year`)}</TableCell>

        {options.displayPartnership && (
          <TableCell rowSpan={3}>{t(`general.partnerships`)}</TableCell>
        )}

        <TableCell rowSpan={3}>{t(`general.school_level`)}</TableCell>
        <TableCell colSpan={9}>{t(`general.product_type`)}</TableCell>
        <TableCell rowSpan={3}>{t(`general.total`)}</TableCell>
      </TableRow>

      <TableRow sx={sxTableHead}>
        <TableCell colSpan={3}>COCO</TableCell>
        <TableCell colSpan={3}>DISE</TableCell>
        <TableCell colSpan={3}>CERTIFICATE</TableCell>
      </TableRow>

      <TableRow sx={sxTableHead}>
        <TableCell>{t(`general.new_item`)}</TableCell>
        <TableCell>{t(`general.old_item`)}</TableCell>
        <TableCell>{t(`general.total`)}</TableCell>
        <TableCell>{t(`general.new_item`)}</TableCell>
        <TableCell>{t(`general.old_item`)}</TableCell>
        <TableCell>{t(`general.total`)}</TableCell>
        <TableCell>{t(`general.new_item`)}</TableCell>
        <TableCell>{t(`general.old_item`)}</TableCell>
        <TableCell>{t(`general.total`)}</TableCell>
      </TableRow>
    </TableHead>
  );
});

StaffListHead.propTypes = {
  options: OptionsShape.isRequired,
};

export default StaffListHead;
